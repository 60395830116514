<template>
    <div v-show="isProperBranch">
        <form ref="formRef" :action="props.url" method="post" enctype="multipart/form-data">
            <input type="hidden" name="_token" :value="props.csrf">
            <div>
                <h4 class="text-secondary">1. Adım: Öğrencileri seçiniz</h4>
                <div>
                    <select name="grade_id" class="mt-1 w-full form-control" v-model="data.grade_id" @change="data.grade_id && getResponsibleCourses(true) && getStudents()">
                        <option value="">Sınıf seçiniz</option>
                        <option :key="grade.id" v-for="grade in grades" :value="grade.id">
                            {{ grade.name }}
                        </option>
                    </select>
                </div>
                <div v-show="data.grade_id" class="mt-4 overflow-y-auto max-h-64" style="-webkit-overflow-scrolling: touch;">
<!--                    <div>-->
<!--                        <button type="button" class="btn btn-xs btn-secondary" @click="selectAllSections">-->
<!--                            Tüm şubeleri ekle-->
<!--                        </button>-->
<!--                    </div>-->
                    <div class="mt-2 grid md:grid-cols-2 gap-x-4 gap-y-2 ">
                        <div :key="i" v-for="(section, i) in students" class="border border-gray-900 rounded-sm p-4">
                            <div class="flex items-center justify-between">
                                <h5 class="mr-4">{{ data.grade_id + ' - ' + section.gradeSection }}</h5>
                                <button type="button" v-if="section.students.filter(s => s.show).length > 0"
                                        class="btn btn-sm btn-yellow" @click="selectAllStudents(section.gradeSection)">
                                    Tümünü Ekle
                                </button>
                                <button type="button" v-else-if="section.students.filter(s => s.show).length === 0"
                                        class="btn btn-sm btn-yellow" @click="deselectAllStudents(section.gradeSection)">
                                    Tümünü Çıkar
                                </button>
                            </div>
                            <div class="mt-2 grid grid-cols-1 divide-y divide-gray-500">
                                <Student :key="j" v-for="(student, j) in section.students" :data="student" :selected="0" @onStudentSelected="onStudentSelected"></Student>
                            </div>
                        </div>
                    </div>
                    <div v-show="data.grade_id && students.length === 0" class="text-secondary text-sm">
                        Bu sınıf düzeyinde pro öğrenci bulunmamaktadır.
                    </div>
                </div>
                <div v-show="selectedStudents.length" class="mt-4">
                    <h5 class="font-semibold">Seçilen öğrenciler ({{ selectedStudents.length }})</h5>
                    <div class="p-4 mt-2 overflow-y-auto max-h-64 grid grid-cols-1 divide-y divide-gray-500 border border-primary-light rounded-sm" style="-webkit-overflow-scrolling: touch;">
                        <Student :key="j" v-for="(student, j) in selectedStudents" :data="student" :selected="1" @onStudentDeselected="onStudentDeselected"></Student>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-show="selectedStudents.length > 0">
                <h4 class="text-secondary">2. Adım: İçerikleri seçiniz</h4>
                <div class="flex flex-col space-x-0 space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2 font-medium text-sm text-gray-900">
                    <div v-show="data.grade_id">
                        <select name="course_id" class="mt-1 w-full form-control" v-model="data.course_id"
                                @change="data.grade_id && data.course_id && courseChanged(true)">
                            <option value="">Ders seçiniz</option>
                            <template v-for="course in courses" :key="course.id">
                                <option :value="course.id" :disabled="course.disabled">{{ course.name }}</option>
                            </template>
                        </select>
                    </div>
                    <div v-show="data.grade_id && data.course_id">
                        <select name="unit_id" class="mt-1 w-full form-control" v-model="data.unit_id"
                                @change="data.unit_id && getContents()">
                            <option value="">Ünite seçiniz</option>
                            <option :key="unit.id" v-for="unit in units" :value="unit.id">
                                {{ unit.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-show="contents.length">
                    <div class="p-2 grid grid-cols xs:grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 place-items-left gap-2 text-xs font-medium">
                        <div class="flex space-x-2 items-center">
                            <img class="w-8 h-8 sm:w-10 sm:h-10" src="/img/courses/types/1.svg" />
                            <div>Konu Anlatımı ve İnteraktif Etkinlikler</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <img class="w-8 h-8 sm:w-10 sm:h-10" src="/img/tests/types/1.svg" />
                            <div>Soru Seti</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <img class="w-8 h-8 sm:w-10 sm:h-10" src="/img/documents/types/1.svg" />
                            <div>Yaprak Test</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <img class="w-8 h-8 sm:w-10 sm:h-10" src="/img/documents/types/3.svg" />
                            <div>Mini Sınav</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <img class="w-8 h-8 sm:w-10 sm:h-10" src="/img/documents/types/2.svg" />
                            <div>Konu Özeti</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <img class="w-8 h-8 sm:w-10 sm:h-10" src="/img/documents/types/7.svg" />
                            <div>Çalışma Kağıdı</div>
                        </div>
                    </div>
                    <div class="mt-2 overflow-y-auto max-h-64 grid grid-cols-1 divide-y divide-gray-500 border border-gray-500 rounded-sm" style="-webkit-overflow-scrolling: touch;">
                        <Task :key="task.type_group_id+'-'+task.id" v-for="(task, i) in contents"
                              :data="task" :selected="0" @onTaskSelected="onTaskSelected"></Task>
                    </div>
                </div>
                <div v-show="selectedContents.length" class="mt-4">
                    <h5 class="font-semibold">Seçilen içerikler ({{ selectedContents.length }})</h5>
                    <div class="mt-2 overflow-y-auto max-h-64 grid grid-cols-1 divide-y divide-gray-500 border border-primary-light rounded-sm" style="-webkit-overflow-scrolling: touch;">
                        <Task :key="task.type_group_id+'-'+task.id" v-for="(task, i) in selectedContents"
                              :data="task" :selected="1" @onTaskDeselected="onTaskDeselected"
                              @onTaskDescriptionUpdated="onTaskDescriptionUpdated" @onTaskSettingsUpdated="onTaskSettingsUpdated"></Task>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-show="selectedStudents.length > 0 && selectedContents.length > 0">
                <h4 class="text-secondary">3. Adım: Zamanlama seçiniz</h4>
                <div class="mt-2 grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <label class="form-control" for="new-task-start-date">Başlangıç Tarihi</label>
                        <input type="datetime-local" class="mt-1 form-control w-full" name="start_date"
                               id="new-task-start-date" v-model="startDate"
                               :min="getDate()" :max="getDate(1)">
                        <div class="form-error error-start-date"></div>
                    </div>
                    <div>
                        <label class="form-control" for="new-task-end-date">Bitiş Tarihi</label>
                        <input type="datetime-local" class="mt-1 form-control w-full" name="end_date" id="new-task-end-date"
                               :min="getDate()" :max="getDate(1)" v-model="endDate">
                        <div class="form-error error-end-date"></div>
                    </div>
                </div>
            </div>
            <div class="mt-4 space-x-2">
                <button type="button" class="btn" @click="sendTask">
                    Gönder
                </button>
            </div>
            <input type="hidden" name="contents" v-model="selectedContentsData" />
            <input type="hidden" name="students" v-model="selectedStudentsData" />
        </form>
        <ProgressBarModal ref="progressBarModalRef"></ProgressBarModal>
        <div v-show="isLoading" class="fixed w-full h-full top-1/2 left-1/2 text-center bg-black bg-opacity-10 z-10" style="transform: translate(-50%,-50%)">
            <div class="w-full h-full flex items-center justify-center">
                <pulse-loader :color="'#03B0B9'" :size="'12px'"></pulse-loader>
            </div>
        </div>
    </div>
    <div v-show="!isProperBranch" class="font-bold">
        Branşınıza uygun ödev içeriği bulunmamaktadır. Lütfen kurum hesap yöneticinizle iletişime geçiniz.
    </div>
</template>

<script setup>
    import {onMounted, ref, watch, nextTick} from "vue";
    import ProgressBarModal from '../../components/modal/ProgressBarModal.vue'
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import Task from './Task.vue'
    import Student from './Student.vue'
    import { useToast } from "vue-toastification";

    const props = defineProps(['user', 'types', 'data', 'url', 'filesUrl', 'csrf']);

    const isLoading = ref(false);
    const isFinished = ref(false);
    const isProperBranch = ref(true);
    const formRef = ref(null);
    const progressBarModalRef = ref(null);
    const startDate = ref(null);
    const endDate = ref(null);

    const grades = ref([]);
    const courses = ref([]);
    const units = ref([]);
    const contents = ref([]);
    const selectedContents = ref([]);
    const students = ref([]);
    const selectedStudents = ref([]);
    const selectedContentsData = ref(null);
    const selectedStudentsData = ref(null);

    const toast = useToast();

    const data = ref({
        type_id: '',
        grade_id: '',
        course_id: '',
        unit_id: '',
    });

    onMounted(() => {
        getResponsibleGrades();
    });

    const getStudents = async () => {
        isLoading.value = true;

        let response = await axios.get('/api/tasks/management/students', {
            params: {
                gradeId: data.value.grade_id
            }
        });

        students.value = response.data.data;
        students.value.forEach(section => section.students.forEach(s => s.show = true));
        selectedStudents.value = [];

        isLoading.value = false;
    }

    const onStudentSelected = (selectedStudent) => {
        selectedStudents.value.push(selectedStudent);
        students.value.forEach(section => section.students.find(s => s.id === selectedStudent.id) ? section.students.find(s => s.id === selectedStudent.id).show = false : '');
    }

    const onStudentDeselected = (deselectedStudent) => {
        selectedStudents.value = selectedStudents.value.filter(s => s.id !== deselectedStudent.id);
        students.value.forEach(section => section.students.find(s => s.id === deselectedStudent.id) ? section.students.find(s => s.id === deselectedStudent.id).show = true : '');
    }

    const selectAllStudents = (gradeSection) => {
        students.value.find(section => section.gradeSection === gradeSection).students.filter(s => s.show === true).forEach(s => onStudentSelected(s));
    }

    const deselectAllStudents = (gradeSection) => {
        students.value.find(section => section.gradeSection === gradeSection).students.filter(s => s.show === false).forEach(s => onStudentDeselected(s));
    }

    const selectAllSections = () => {
        // students.value.find(section => section.gradeSection === gradeSection).students.filter(s => s.show === true).forEach(s => onStudentSelected(s));
    }

    const courseChanged = async (reset = false) => {
        await getUnits(reset);
    }

    const getResponsibleGrades = async () => {
        isLoading.value = true;

        let response;

        if (props.user.is_allowed_for_all_branches) {
            response = await axios.get('/api/grades/?schoolLevelId=' + props.user.school_level_id);
        } else {
            response = await axios.get('/api/responsible-grades/' + props.user.school_level_id + '/' + props.user.branch_id);
        }

        grades.value = response.data.data;

        if (!grades.value || grades.value.length === 0) {
            isProperBranch.value = false;
        }

        if (data.value.grade_id && data.value.course_id) {
            await getResponsibleCourses(false);
            await courseChanged(false);
        }

        isLoading.value = false;
    }

    const getResponsibleCourses = async (reset = false) => {
        if (!reset && props.courses && props.courses.length > 0) {
            courses.value = props.courses;
        } else {
            isLoading.value = true;

            let response;
            if (props.user.is_allowed_for_all_branches) {
                response = await axios.get('/api/courses', {
                    params: {
                        gradeId: data.value.grade_id
                    }
                });

                if (data.value.grade_id == 11) {
                    const grade12Response = await axios.get('/api/courses', {
                        params: { gradeId: 12 }
                    });

                    const allCourses = [
                        { id: 'header-11', name: '-- 11. SINIF DERSLERİ --', disabled: true },
                        ...response.data.data,
                        { id: 'header-12', name: '-- 12. SINIF DERSLERİ --', disabled: true },
                        ...grade12Response.data.data
                    ];

                    response.data.data = allCourses;
                }
            } else {
                response = await axios.get('/api/responsible-courses/' + data.value.grade_id + '/' + props.user.branch_id);

                if (data.value.grade_id == 11) {
                    const grade12Response = await axios.get('/api/responsible-courses/12/' + props.user.branch_id);

                    const allCourses = [
                        { id: 'header-11', name: '-- 11. SINIF DERSLERİ --', disabled: true },
                        ...response.data.data,
                        { id: 'header-12', name: '-- 12. SINIF DERSLERİ --', disabled: true },
                        ...grade12Response.data.data
                    ];

                    response.data.data = allCourses;
                }
            }

            courses.value = response.data.data;
            data.value.course_id = '';
            selectedContents.value = [];

            isLoading.value = false;
        }
    }

    const getUnits = async (reset = false) => {
        isLoading.value = true;
        let response = await axios.get('/api/units', {
            params: {
                'gradeId': data.value.grade_id,
                'courseId': data.value.course_id
            }
        });
        isLoading.value = false;

        units.value = response.data.data;

        if (reset) {
            data.value.unit_id = '';
        }
    }

    const getContents = async () => {
        isLoading.value = true;
        let response = await axios.get('/api/tasks/management/contents?unitId=' + data.value.unit_id);

        contents.value = response.data.data;
        contents.value.forEach(item => {
            item.show = true;
        });

        isLoading.value = false;
    }

    // When add button of a task is pressed, then this selected task item is added to selectedContents array
    const onTaskSelected = async (item) => {

        // Check student conditions for the task item by sending request to API
        let response = await preCheck(item);

        // If there is an error, show it by using the toast
        if (response.code === 1003) {
            if (!confirm(response.message)) {
                return false;
            }
        } else if (response.message) {
            toast.error(response.message, {
                type: 'warning',
                timeout: 7000
            });

            return false;
        }

        // Initiate new attributes for the selected task item
        item.description = '';
        item.assessment_type = null;
        item.file_upload= null;

        // Add the selected task item to selectedContents array of task items
        selectedContents.value.push(item);

        // Find the selected task item in the contents array (the all contents without selected contents)
        let content = contents.value.find(c => c.id === item.id && c.type_group_id === item.type_group_id);

        // Hide the selected task item on the contents array
        content.show = false;
    }

    // When remove button of a task is pressed, then this task item is added to selectedContents array
    const onTaskDeselected = (item) => {
        // Find the task item in the selectedContents array and delete it
        selectedContents.value = selectedContents.value.filter(c => c.id !== item.id || c.type_group_id !== item.type_group_id);

        // Find the task item in contents array and change its show attribute to true
        // Therefore, corresponding widget appeared in contents area again.
        contents.value.find(c => c.id === item.id && c.type_group_id === item.type_group_id).show = true;
    }

    // When description is updated, onTaskDescriptionUpdated function is called
    // This function finds the task item in the selectedContents array, and saves new description into corresponding item in selectedContents array
    const onTaskDescriptionUpdated = (item, description) => {
        // Find the setting updated task item from contents array
        let content = selectedContents.value.find(c => c.id === item.id && c.type_group_id === item.type_group_id);

        // Save new settings for the item in the selectedContents array
        content.description = description;
    }

    // When save button in any SettingsModal is pressed, onTaskSettingsUpdated function is called
    // This function finds the task item in the selectedContents array, and saves the settings into corresponding item in selectedContents array
    const onTaskSettingsUpdated = (item, settings) => {
        // Find the setting updated task item from contents array
        let content = selectedContents.value.find(c => c.id === item.id && c.type_group_id === item.type_group_id);

        // Save new settings for the item in the selectedContents array
        content.assessment_type = settings.value.assessmentType;
        content.file_upload = settings.value.fileUpload;
    }

    const getDate = (addYears = 0) => {
        let date = new Date();
        date.setFullYear(date.getFullYear() + addYears);

        return date.getFullYear() + '-' + padZero(date.getMonth() + 1) + '-' + padZero(date.getDate()) + ' ' + padZero(date.getHours()) + ':' + padZero(date.getMinutes());
    }

    const padZero = (str, length = 2) => {
        return (str+"").padStart(length, "0");
    }

    const preCheck = async (content) => {
        let message = null;
        let code = null;

        isLoading.value = true;

        await axios.get('/api/tasks/management/precheck', {
            params: {
                'gradeId': data.value.grade_id,
                'studentIds': selectedStudents.value.map(function(item) {
                    return item['id'];
                }),
                'contentId': content.id,
                'contentTypeGroupId': content.type_group_id,
            }
        }).then((response) => {
            message = response.data.data.message;
            code = response.data.data.code;
        }).catch(e => {
            if (!e.response) {
                message = 'Lütfen internet bağlantınızı kontrol ediniz!';
            } else if (e.response.status === 422) {
                message = 'Geçersiz istek. Lütfen sayfayı yenileyiniz!';
            } else {
                message = 'Bir hata oluştu, lütfen tekrar deneyin.'
            }
        }).then(() => {
            isLoading.value = false;
        });

        return {
            message: message,
            code: code
        }
    }

    // Validate the elements in the form before sending the form
    // If the validation is failed, then show error by using the toast
    const validateForm = () => {
        // If there is no item in selectedStudents array, then show error by using the toast
        if (selectedStudents.value.length === 0) {
            toast.error('Lütfen en az 1 öğrenci seçiniz', {
                type: 'error',
                timeout: 3000
            });
            return false;
        }

        // If there is no item (task) in selectedContents array, then show error by using the toast
        if (selectedContents.value.length === 0) {
            toast.error('Lütfen en az 1 içerik seçiniz', {
                type: 'error',
                timeout: 3000
            });
            return false;
        }

        // Check whether all settings of each item in selectedContents array are selected or not
        let settingsAreSelected = true;

        // Check each item in selectedContents array
        selectedContents.value.forEach(content => {
            // If the current content is document and type of it is one of konu özeti or çalışma kağıdı
            if (content.type_group_id === 2 && (content.type_id === 2 || content.type_id === 7)) {

                // If any of the settings belong to the current content is null, then show error message by using the toast
                if (content.file_upload === null || content.assessment_type === null) {
                    toast.error(content.title + ' isimli doküman için ayarları seçiniz!', {
                        type: 'error',
                        timeout: 3000
                    });

                    // One of settings of an item is not selected in selectedContents array
                    settingsAreSelected = false;

                    // Exit from foreach
                    return false;
                }
            }
        });

        // If one of settings of an item is not selected in selectedContents array, then return false for validation
        if (!settingsAreSelected) {
            return false;
        }

        // If start date of tasks is not selected, then show error by using the toast
        if (!startDate.value) {
            toast.error('Başlangıç tarihi seçiniz', {
                type: 'error',
                timeout: 3000
            });
            return false;
        }

        // If end date of tasks  is not selected, then show error by using the toast
        if (!endDate.value) {
            toast.error('Bitiş tarihi seçiniz', {
                type: 'error',
                timeout: 3000
            });
            return false;
        }

        return true;
    }

    // Send tasks by
    const sendTask = () => {

        // Start the validation
        if (!validateForm()) {
            return;
        }

        // Send the form
        formRef.value.submit();
    }

    // Watch selectedContents array which contains selected task items by teacher
    // Convert the data of selectedContents array to JSON and put into hidden element inside the form
    // Because selectedContents data must be prepared before sending the form
    watch(selectedContents, (newData) => {
        selectedContentsData.value = JSON.stringify(newData);
    }, { deep: true });

    // Watch selectedStudents array which contains selected task items by teacher
    // Convert the data of selectedStudents array to JSON and put into hidden element inside the form
    // Because selectedStudents data must be prepared before sending the form
    watch(selectedStudents, (newData) => {
        selectedStudentsData.value = JSON.stringify(newData);
    }, { deep: true });
</script>

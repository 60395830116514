import $ from "jquery";
import "magnific-popup";
import 'mediaelement';
import axios from 'axios';

// window.Echo = new Echo({
//     broadcaster: 'reverb',
//     key: import.meta.env.VITE_REVERB_APP_KEY,
//     wsHost: import.meta.env.VITE_REVERB_HOST,
//     wsPort: import.meta.env.VITE_REVERB_PORT,
//     wssPort: import.meta.env.VITE_REVERB_PORT,
//     forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
import {createApp} from 'vue/dist/vue.esm-bundler';
import * as CookieManager from './utils/cookie';
import * as TourManager from './utils/tour';
import * as AnalyticsManager from './utils/analytics';
import {CountdownManager} from "./utils/countdown";
import {TypewriterManager} from "./utils/typeWriterManager";
import {initMediaElement} from './utils/mediaelement';
import {initMagnificPopup, initYoutubeMagnificPopups} from './utils/magnificpopup';
import {initLazyLoad} from "./utils/lazyload.js";
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import {cookieConsentConfig} from './utils/cookieConsent.js';
import './landing/menu';
import './landing/tabs';
import './components/tabs';
import './components/tabs-select';
import './components/sidebar';
import './components/slider';
import './components/table';
import NotificationManager from './components/notification/NotificationManager';
import EventBus from './events'
import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
import Inputmask from 'inputmask'
import * as htmlToImage from 'html-to-image';
import VueFullScreen from "vue-fullscreen"
import VueFireworks from 'vue3-damp-fireworks'

import ClassManagement from './classManagement/ClassManagement.vue'
import LearningTest from './learningTest/LearningTest.vue'
import DocumentManagement from './contentManagement/document/DocumentManagement.vue'
import MyTasks from './tasks/MyTasks.vue'
import MyLastTasks from './tasks/MyLastTasks.vue'
import TaskReport from './tasks/TaskReport.vue'
import TaskManagement from './tasks/management/TaskManagement.vue'
import QuestionManagement from './exercise/management/QuestionManagement.vue'
import Exercise from './exercise/Exercise.vue'
import ErrorReportModal from './components/modal/ErrorReportModal.vue'
import WelcomeModal from './promotion/WelcomeModal.vue'
import UserDropdownMenu from "./components/user/DropdownMenu.vue";
import AutoComplete from "./components/form/AutoComplete.vue";
import MultiSelect from './components/form/MultiSelect.vue'
import BackToTop from './components/BackToTop.vue'
import ShowMore from './components/ShowMore.vue'
import StarRating from "./components/StarRating.vue";
import LearningContentFinishModal from './components/modal/LearningContentFinishModal.vue'
import TeachersDayModal from './components/modal/TeachersDayModal.vue'
import BarChart from './components/chart/BarChart.vue'
import DoughnutChart from './components/chart/DoughnutChart.vue'
import DataTable from './components/table/DataTable.vue'
import VideoPlayer from './components/VideoPlayer.vue'

window.$ = $;
window.jQuery = $;

import('jquery-lazyload');
import('jquery-toast-plugin');

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// CSRF token ayarla
const token = document.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.getAttribute('content');
}

const VueInputMask = {
    install: function (Vue) {
        Vue.directive('mask', {
            mounted: function (el, binding) {
                Inputmask(binding.value).mask(el);
            }
        });
    }
};

window.CookieManager = CookieManager;
window.CountdownManager = CountdownManager;
window.TypewriterManager = TypewriterManager;
window.htmltoimage = htmlToImage;
window.TourManager = TourManager;

// ArtPlayer vue.js component
if (document.getElementById('app')) {
    const app = createApp({});
    app.component('video-player', VideoPlayer);
    app.mount('#app');
}

// Diğer Vue uygulamaları
if (document.getElementById('learning-test-display-container')) {
    createApp({
        components: {
            LearningTest,
        }
    })
        .use(Toast, {
            transition: "Vue-Toastification__fade",
            maxToasts: 20,
            newestOnTop: true,
            position: POSITION.TOP_CENTER,
            toastClassName: "derslig-toast"
        })
        .mount('#learning-test-display-container');
}

if (document.getElementById('class-management-container')) {
    createApp({ components: {
            ClassManagement,
        }})
        .use(VueFireworks)
        .use(VueFullScreen, {name: "fs"})
        .mount('#class-management-container');
}

if (document.getElementById('document-management-container')) {
    createApp({
        components: { DocumentManagement}
    })
        .mount('#document-management-container');
}

if (document.getElementById('my-tasks-container')) {
    createApp({
        components: { MyTasks }
    })
        .use(Toast, {
            transition: "Vue-Toastification__fade",
            maxToasts: 20,
            newestOnTop: true,
            hideProgressBar: true,
            icon: true,
            position: POSITION.BOTTOM_RIGHT,
            toastClassName: "derslig-toast"
        })
        .mount('#my-tasks-container');
}

if (document.getElementById('my-last-tasks-container')) {
    createApp({
        components: { MyLastTasks }
    })
        .use(Toast, {
            transition: "Vue-Toastification__fade",
            maxToasts: 20,
            newestOnTop: true,
            hideProgressBar: true,
            icon: true,
            position: POSITION.BOTTOM_RIGHT,
            toastClassName: "derslig-toast"
        })
        .mount('#my-last-tasks-container');
}

if (document.getElementById('task-report-container')) {
    createApp({
        components: {
            TaskReport
        }
    })
        .use(Toast, {
            transition: "Vue-Toastification__fade",
            maxToasts: 20,
            newestOnTop: true,
            hideProgressBar: true,
            icon: true,
            position: POSITION.BOTTOM_RIGHT,
            toastClassName: "derslig-toast"
        })
        .mount('#task-report-container');
}

if (document.getElementById('task-management-container')) {
    createApp({
        components: { TaskManagement }
    })
        .use(Toast, {
            transition: "Vue-Toastification__fade",
            maxToasts: 20,
            newestOnTop: true,
            hideProgressBar: true,
            icon: true,
            position: POSITION.BOTTOM_RIGHT,
            toastClassName: "derslig-toast"
        })
        .mount('#task-management-container');
}

if (document.getElementById('exercise-question-management-container')) {
    createApp({
        components: { QuestionManagement }
    })
        .mount('#exercise-question-management-container');
}

if (document.getElementById('exercise-display-container')) {
    createApp({
        components: { Exercise }
    })
        .mount('#exercise-display-container');
}

if (document.getElementById('school-panel-dashboard-charts-container')) {
    createApp({
        components: { BarChart, DoughnutChart, DataTable }
    })
        .mount('#school-panel-dashboard-charts-container');
}

if (document.getElementById('error-report-modal') && document.getElementById('open-report-modal-btn')) {
    let reportBtn = document.getElementById('open-report-modal-btn');
    reportBtn.addEventListener('click', () => {
        EventBus.trigger('open-report-modal', {
            'type': reportBtn.dataset.type,
            'url': reportBtn.dataset.url,
            'title': reportBtn.dataset.title,
            'learningContentId': (reportBtn.dataset.id ? reportBtn.dataset.id : ''),
            'slide': (reportBtn.dataset.slide ? reportBtn.dataset.slide : '')
        });
    });

    createApp({
        components: { ErrorReportModal }
    })
        .mount('#error-report-modal');
}

if (document.getElementById('learning-content-finish-modal') && document.getElementById('open-learning-content-finish-modal-btn')) {
    let finishBtn = document.getElementById('open-learning-content-finish-modal-btn');
    finishBtn.addEventListener('click', () => {
        EventBus.trigger('open-learning-content-finish-modal');
    });

    createApp({
        components: { LearningContentFinishModal }
    })
        .mount('#learning-content-finish-modal');
}

if (document.getElementById('welcome-modal')) {
    createApp({
        components: { WelcomeModal }
    })
        .use(VueInputMask)
        .mount('#welcome-modal');

    let welcomeModalCookieName = 'welcomeModalOK';

    let openWelcomeModalBtn = document.getElementsByClassName('open-welcome-modal-btn');
    if (openWelcomeModalBtn.length > 0) {
        Array.from(openWelcomeModalBtn).forEach(i => {
            i.addEventListener('click', () => {
                EventBus.trigger('open-welcome-modal');
            });
        });
    }

    if (CookieManager.getCookie(welcomeModalCookieName)) {
        Array.from(openWelcomeModalBtn).forEach(i => {
            i.classList.remove('hidden');
        });
    } else {
        EventBus.trigger('open-welcome-modal-first');
        CookieManager.setCookie(welcomeModalCookieName, 1, 365 * 24 * 60);
    }
}

if (document.getElementById('teachers-day-modal-container')) {
    let teachersDayCookieName = 'teachers_day_modal_' + (new Date()).getFullYear();

    if (!CookieManager.getCookie(teachersDayCookieName)) {
        createApp({
            components: { TeachersDayModal }
        })
            .mount('#teachers-day-modal-container');

        CookieManager.setCookie(teachersDayCookieName, 1, 4 * 24 * 60);
    }
}

if (document.getElementById('user-dropdown-menu')) {
    createApp({
        components: { UserDropdownMenu }
    })
        .mount('#user-dropdown-menu');
}

if (document.getElementById('multi-select')) {
    createApp({
        components: { MultiSelect }
    })
        .mount('#multi-select');
}

if (document.getElementById('auto-complete')) {
    createApp({
        components: { AutoComplete }
    })
        .mount('#auto-complete');
}

if (document.getElementById('star-rating')) {
    createApp({
        components: { StarRating }
    })
        .mount('#star-rating');
}

if (document.getElementById('show-more-container')) {
    createApp({
        components: { ShowMore }
    })
        .mount('#show-more-container');
}

if (document.getElementById('back-to-top-container')) {
    createApp({
        components: { BackToTop }
    })
        .mount('#back-to-top-container');
}

window.addEventListener('DOMContentLoaded',function(){
    AnalyticsManager.track();
    initLazyLoad();
    initMagnificPopup();
    initYoutubeMagnificPopups();
    initMediaElement();

    // If any of derslig_webview or cc_cookie
    if (CookieManager.getCookie('derslig_webview') === false && CookieManager.getCookie('cc_cookie') === false) {
        CookieConsent.run(cookieConsentConfig);
    }

    // TODO: Fix cookie domain error
    CookieManager.deleteCookie("XSRF-TOKEN", 'www' + CookieManager.getCookieDomain());

    const hlsPlayer = document.querySelector('hls-video');
    if (hlsPlayer) {
        hlsPlayer.addEventListener('loadedmetadata', () => {
            console.log('Video yüklendi');
        });
    }
});

document.addEventListener('DOMContentLoaded', function() {
    const notificationButton = document.getElementById('notification-button');
    const notificationDropdown = document.getElementById('notification-dropdown');

    if (!notificationButton || !notificationDropdown) {
        return;
    }

    notificationButton.addEventListener('click', function(e) {
        e.stopPropagation();
        notificationDropdown.classList.toggle('hidden');
    });

    document.addEventListener('click', function(e) {
        if (!notificationDropdown.contains(e.target) && !notificationButton.contains(e.target)) {
            notificationDropdown.classList.add('hidden');
        }
    });
});

// Bildirim sistemini başlat
$(document).ready(() => {
    NotificationManager.init();
});

// Email Doğrulama Modal
$(document).ready(function() {
    const modal = document.getElementById('emailWarningModal');

    if (!modal)
        return;

    // Eğer profil düzenleme sayfasındaysak modal'ı gösterme
    if (window.location.pathname === '/profil/duzenle') {
        modal.classList.add('hidden');
        return;
    }

    if (window.auth?.user?.email_rejected === 1) {
        modal.classList.remove('hidden');

        const overlay = document.querySelector('#emailWarningModal .fixed.inset-0');
        if (overlay) {
            overlay.addEventListener('click', () => {
                modal.classList.add('hidden');
            });
        }

        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                modal.classList.add('hidden');
            }
        });
    } else {
        modal.classList.add('hidden');
    }
});

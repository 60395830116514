<template>
    <div class="w-full flex flex-col space-y-6 py-10">
        <div class="w-full flex flex-col space-y-4 items-center justify-center">
            <div class="w-full bg-secondary text-white text-left p-4" v-if="students.length == 0 || selectedIndexError || error">
                <div v-if="error">{{ error }}</div>
                <div v-if="selectedIndexError">{{ selectedIndexError }}</div>
                <div v-if="students.length == 0">Öğrenci sayısı yetersiz.</div>
            </div>

            <div class="flex flex-col space-y-4 items-center justify-center" v-if="students.length > 0">
                <div :class="!loading ? 'visible' : 'invisible'">
                    <ValueSelect :obj="options['groupSize']" :prefix="['Grup']"/>
                </div>

                <div>
                    <div class="flex space-x-2 mt-4">
                        <div class="bg-yellow-default rounded-lg px-4 py-2 flex items-center justify-center text-xl" :class="!loading ? 'opacity-100 cursor-pointer' : 'opacity-50'"
                        @click="startSelection">
                        {{ group.length == 0 ? 'Gruplamayı Başlat' : 'Yeniden Grupla' }}
                    </div>

                    <div class="bg-yellow-default rounded-lg px-4 py-2 flex items-center justify-center text-xl" :class="!loading ? 'opacity-100 cursor-pointer' : 'opacity-50'"
                        @click="selectAllGroupSubStudent = true"
                        v-if="group.length > 0 && !loading">
                        Öğrenci Seç
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div class="flex space-x-8 items-center justify-center my-6" v-if="subSelectedStudents.length > 0 && !loading">
            <div   v-for="s, key in subSelectedStudents" :key="key">
                <div v-if="s || (s == 0 && s != null)">
                    <div class="flex flex-col space-y-1 items-center justify-center">
                        <div class="font-bold">{{ key + 1 }}. Grup</div>
                        <div><Down size="30" /></div>
                    </div>

                    <div>
                        <Student  @event="students_select_button({e: $event, index: key})" :buttons="['applause', 'falseAnswer', 'trueAnswer']" :deactive-buttons="[]" :event="event" :obj="{...students[s], index: s}" />
                    </div>
                </div>
                <div class="flex items-center justify-center  bg-gray-100 border-2 p-4" v-else>Bekleniyor.</div>
            </div>
        </div>



        <!-- Seçim Bu alanda-->
        <div class="flex flex-wrap w-full">
            <div class="flex  p-2 bg-gray-50 min-h-max w-1/2"  v-for="i,key in group" :key="key">
                <groupVue :soundStart="soundStart" :group_points="group_points[key]" :selectAll="selectAllGroupSubStudent" @selectedd="subSelectedStudents[key] = $event" @loading="loading = $event" :options="options" :showCount="showCount" :group="i" :group_index="key" :event="event" :students="students" v-if="!newGroupLoading"/>
            </div>
        </div>



        <div class="border-2 border-gray-200 bg-gray-100 mt-24 p-2 font-bold">
            Sınıf Listesi
        </div>
        <div>
            <div class="flex flex-wrap mt-2">
                <div class="w-1/6 flex items-center justify-center p-2" v-for="s, index in students" :key="index">
                    <Student :event="event" :buttons="['deactiveSelection','activeSelection']" :info="true" :deactive-buttons="[!s.selectable ? 'deactiveSelection' : 'activeSelection']" :obj="{...s, index}"/>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import {ref, defineProps, defineEmits, computed, reactive, watch} from 'vue'
import useSound from '../../sounds'
import Student from '../other/student.vue'
import useRandIndex from '../../compositions/useRandIndex'
import ValueSelect from '../other/valueSelect.vue'
import groupVue from './studentsRandomGroup/group.vue';
import {Down} from '@icon-park/vue-next';
import { earnGroupingBadge } from '../../../helpers/badge'

const {soundStart, soundSetVolume} = useSound({files_url:null})
const {selectedIndexError, select, selectingIndex, getRandomSelectIndex, excludedIndexes, selectableLength, selectableSize, selectableIndexes} = useRandIndex()

const props               = defineProps(['options', 'grade', 'event', 'soundStart'])
const emit                = defineEmits(['loading'])
const loading             = ref(false)
const error               = ref(null)
const group               = ref([])
const showCount           = ref(0)
const newGroupLoading     = ref(false)
const subSelectedStudents = ref([])
const selectAllGroupSubStudent = ref(false)
const group_points         = ref([])

const students = computed(() => {
    return reactive(props.options['displayStudentName'].value ? props.grade.students : props.grade.noNameStudents)
})

watch(students, (cStudents) => {
    error.value = null
    if (cStudents.filter(i => i.selectable).length == 0) {
        error.value = "Seçilebilcek öğrenci sayısı sıfır olamaz."
    }

}, {deep: true})

watch(loading, (cLoading) => {
    emit('loading', cLoading)
    setTimeout(() => newGroupLoading.value = false, 100);
})

watch(selectAllGroupSubStudent, () => {
    setTimeout(() => selectAllGroupSubStudent.value = false, 100);
})


const students_select_button = (e) => {
    let point = group_points.value[e.index].point
    if (e.e == 'trueAnswer') {
        point += 1
    }else if (e.e == 'falseAnswer') {
        point -= 1
    }
    group_points.value[e.index].point = point
}

const startSelection = async () => {
    if (loading.value) return false
    loading.value = !loading.value
    error.value   = null
    group.value   = []
    showCount.value = 0
    newGroupLoading.value = true
    subSelectedStudents.value = []

    // Ekip Kurucu Rozeti için API çağrısı yap
    earnGroupingBadge()
        .then(result => {
            if (result && result.status === 'ok' && result.data) {
            }
        })
        .catch(error => {
        });

    //seçimi iptal edilenleri eleyelim
    let index = -1;
    let excludedIndexesArr = students.value.map(i => {
        index++
        return !i.selectable ?  index : 'x'
    }).filter(x => x != 'x')
    excludedIndexes.value = [...excludedIndexesArr]

    selectableLength.value = students.value.length

    let groupSize        = props.options['groupSize'].value
    let groupStudentSize = Math.floor(students.value.filter(i => i.selectable).length / groupSize)


    group_points.value = Array.from({ length: groupSize }, () => 0).map(i => {
        return {point: 0}
    })

    if (groupStudentSize == 0) {
        error.value = "Öğrenci Sayısından çok grup oluşturamazsınız."
        loading.value = false
        return false
    }

    selectableSize.value = groupStudentSize
    for (let g = 0; g < groupSize; g++) {
        select()
        group.value.push(selectingIndex.value)
    }

    for (let g = 0; g < selectableIndexes.value.length; g++) {
        group.value[g].push(selectableIndexes.value[g])
    }

    let i = 0;
    const interval = setInterval(() => {
        showCount.value++
        props.soundStart(4)
        if (i == groupStudentSize + 1) {
            loading.value   = !loading.value
            selectableIndexes.value = []
            showCount.value = groupStudentSize + 1
            props.soundStart(3)
            clearInterval(interval)
        }
        i++
    }, props.options['randomSelectionSpeed'].value * 500);


}






</script>

import axios from 'axios';
import $ from 'jquery';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

const NotificationManager = {
    lastCount: null,

    playNotificationSound() {
        const audio = new Audio('/audio/notification.mp3');
        audio.volume = 0.5;
        audio.play().catch(error => {
            console.error('Bildirim sesi çalınamadı:', error);
        });
    },

    init() {
        if (document.querySelector('.notification-count')) {
            this.loadUnreadCount();
            this.loadNotifications();
            this.bindEvents();

            setInterval(() => {
                this.loadUnreadCount();
                this.loadNotifications();
            }, 15000);
        }
    },

    bindEvents() {
        $(document).on('click', '[data-notification-id]', (e) => {
            const id = $(e.currentTarget).data('notification-id');
            this.markAsRead(id);
        });

        $(document).on('click', '#mark-all-read', () => {
            this.markAllAsRead();
        });

        const notificationButton = document.getElementById('notification-button');
        if (notificationButton) {
            notificationButton.addEventListener('click', () => {
                this.loadNotifications();
            });
        }
    },

    loadNotifications() {
        const dropdown = document.getElementById('notification-dropdown');
        if (!dropdown) return;

        axios.get('/bildirimler/latest')
            .then(response => {
                if (response.data.success) {
                    const notifications = response.data.notifications;
                    const notificationList = dropdown.querySelector('.notifications-list');
                    if (notificationList) {
                        notificationList.innerHTML = this.renderNotifications(notifications);
                    }
                }
            })
            .catch(error => {
                console.error('Bildirimler yüklenemedi:', error);
            });
    },

    renderNotifications(notifications) {
        if (notifications.length === 0) {
            return `
                <div class="p-4 sm:p-8 text-center">
                    <div class="mb-4">
                        <svg class="w-10 h-10 sm:w-12 sm:h-12 mx-auto text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
                        </svg>
                    </div>
                    <p class="text-sm sm:text-base text-gray-500">Bildiriminiz bulunmuyor</p>
                </div>
            `;
        }

        return notifications.map(notification => `
            <a href="${notification.url}"
               class="block px-3 sm:px-4 py-3 hover:bg-gray-50 transition-colors relative border-b border-gray-100 last:border-b-0 ${!notification.read ? 'bg-blue-50/60' : ''}"
               data-notification-id="${notification.id}">
                <div class="flex items-start">
                    <div class="flex-1 min-w-0">
                        <p class="text-xs sm:text-sm font-medium text-gray-900 mb-1">
                            ${notification.title}
                        </p>
                        <div class="flex justify-between items-start">
                            <div class="flex-1">
                                <p class="text-xs sm:text-sm text-gray-600 line-clamp-2 mb-1">
                                    ${notification.message}
                                </p>
                                <span class="text-2xs sm:text-xs text-gray-500">
                                    ${notification.created_at}
                                </span>
                            </div>
                            ${!notification.read ? '<div class="flex-shrink-0 ml-3 mt-1.5"><div class="w-2.5 h-2.5 bg-primary-500 rounded-full"></div></div>' : ''}
                        </div>
                    </div>
                </div>
            </a>
        `).join('') + `
            <div class="p-3 sm:p-4 text-center border-t border-gray-100 bg-gray-50/50">
                <a href="/bildirimler" class="text-xs sm:text-sm font-medium text-primary-600 hover:text-primary-700">
                    Tüm Bildirimleri Gör
                </a>
            </div>
        `;
    },

    loadUnreadCount() {
        axios.get('/bildirimler/unread-count')
            .then(response => {
                if (response.data.success) {
                    const count = response.data.count;
                    const badge = document.querySelector('.notification-count');

                    // Eğer lastCount null değilse ve yeni bildirim geldiyse ses çal
                    if (this.lastCount !== null && count > this.lastCount) {
                        this.playNotificationSound();
                    }

                    this.lastCount = count;

                    if (badge) {
                        if (count > 0) {
                            badge.textContent = count;
                            badge.classList.remove('hidden');
                            badge.classList.add('flex');
                        } else {
                            badge.classList.remove('flex');
                            badge.classList.add('hidden');
                        }
                    }
                }
            })
            .catch(error => {
                console.error('Bildirim sayısı yüklenemedi:', error);
                const badge = document.querySelector('.notification-count');
                if (badge) {
                    badge.classList.remove('flex');
                    badge.classList.add('hidden');
                }
            });
    },

    markAsRead(id) {
        axios.post('/bildirimler/mark-as-read', { id })
            .then(response => {
                if (response.data.success) {
                    this.loadUnreadCount();
                }
            })
            .catch(error => {
                console.error('Bildirim okundu işaretlenemedi:', error);
            });
    },

    markAllAsRead() {
        axios.post('/bildirimler/mark-all-as-read', {}, {
            headers: {
                'X-CSRF-TOKEN': token
            }
        })
            .then(response => {
                if (response.data.success) {
                    this.loadUnreadCount();
                }
            })
            .catch(error => {
                console.error('Bildirimler okundu işaretlenemedi:', error);
            });
    }
};

export default NotificationManager;

<template>
  <div class="art-player-wrapper">
    <div ref="artplayer" class="art-video-player"></div>
  </div>
</template>

<script>
import Artplayer from 'artplayer';
import Hls from 'hls.js';

export default {
  name: 'VideoPlayer',
  props: {
    src: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'video/mp4'
    },
    subtitles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      art: null,
      hls: null
    }
  },
  mounted() {
    this.initializePlayer();
  },
  beforeUnmount() {
    this.destroyPlayer();
  },
  methods: {
    initializePlayer() {
      try {
        const options = {
          container: this.$refs.artplayer,
          url: this.src,
          poster: this.poster,
          volume: 0.5,
          isLive: false,
          muted: false,
          autoplay: false,
          pip: true,
          autoSize: false,
          autoMini: true,
          screenshot: false,
          setting: true,
          loop: false,
          flip: true,
          playbackRate: true,
          aspectRatio: true,
          fullscreen: true,
          fullscreenWeb: true,
          subtitleOffset: true,
          miniProgressBar: true,
          mutex: true,
          backdrop: true,
          playsInline: true,
          autoPlayback: true,
          airplay: true,
          theme: '#e50069',
          lang: 'tr',
          subtitle: {
            url: this.subtitles.length > 0 ? this.subtitles[0].src : '',
            type: 'vtt',
            style: {
              color: '#fff',
              fontSize: '20px',
              textShadow: '0 0 2px #000',
              fontFamily: 'Arial, sans-serif'
            },
            encoding: 'utf-8',
            default: true,
            escape: false,
            onLoad: (textTrack) => {
              console.log('Altyazı yüklendi:', textTrack);
            },
            onError: (error) => {
              console.error('Altyazı yüklenirken hata:', error);
            }
          },
          controls: [
            {
              position: 'left',
              html: '<div class="volume-control"><div class="volume-icon"></div><div class="volume-panel"><div class="volume-slider"></div></div></div>',
              style: {
                padding: '0 10px',
              },
              mounted: ($control) => {
                // ... volume control code ...
              },
            },
            {
              position: 'right',
              html: 'Altyazı',
              selector: this.subtitles.map(subtitle => ({
                html: subtitle.label,
                url: subtitle.src,
                default: subtitle.default
              })),
              onSelect: function(item) {
                this.subtitle.switch(item.url);
                return item.html;
              },
            },
            {
              position: 'right',
              html: 'Kalite',
              selector: [
                {
                  html: '1080p',
                  default: true,
                },
                {
                  html: '720p',
                },
                {
                  html: '480p',
                },
                {
                  html: '360p',
                },
              ],
            },
            {
              position: 'right',
              html: 'Hız',
              selector: [
                {
                  html: '2.0x',
                  value: 2,
                },
                {
                  html: '1.5x',
                  value: 1.5,
                },
                {
                  html: '1.0x',
                  value: 1,
                  default: true,
                },
                {
                  html: '0.5x',
                  value: 0.5,
                },
              ],
            },
          ],
          icons: {
            volume: `<svg width="24" height="24" viewBox="0 0 24 24"><path d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z"/></svg>`,
            volumeClose: `<svg width="24" height="24" viewBox="0 0 24 24"><path d="M12,4L9.91,6.09L12,8.18M4.27,3L3,4.27L7.73,9H3V15H7L12,20V13.27L16.25,17.53C15.58,18.04 14.83,18.46 14,18.7V20.77C15.38,20.45 16.63,19.82 17.68,18.96L19.73,21L21,19.73L12,10.73M19,12C19,12.94 18.8,13.82 18.46,14.64L19.97,16.15C20.62,14.91 21,13.5 21,12C21,7.72 18,4.14 14,3.23V5.29C16.89,6.15 19,8.83 19,12M16.5,12C16.5,10.23 15.5,8.71 14,7.97V10.18L16.45,12.63C16.5,12.43 16.5,12.21 16.5,12Z"/></svg>`,
          },
          moreVideoAttr: {
            crossOrigin: 'anonymous',
          },
          customType: {
            m3u8: (video, url) => {
              if (Hls.isSupported()) {
                this.hls = new Hls();
                this.hls.loadSource(url);
                this.hls.attachMedia(video);
                
                this.hls.on(Hls.Events.ERROR, (event, data) => {
                  if (data.fatal) {
                    switch (data.type) {
                      case Hls.ErrorTypes.NETWORK_ERROR:
                        this.hls.startLoad();
                        break;
                      case Hls.ErrorTypes.MEDIA_ERROR:
                        this.hls.recoverMediaError();
                        break;
                      default:
                        this.destroyPlayer();
                        break;
                    }
                  }
                });
              } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = url;
              }
            },
          },
        };

        this.art = new Artplayer(options);
      } catch (error) {
        this.destroyPlayer();
      }
    },
    destroyPlayer() {
      if (this.hls) {
        this.hls.destroy();
        this.hls = null;
      }
      if (this.art) {
        this.art.destroy();
        this.art = null;
      }
    }
  },
  watch: {
    src: {
      handler(newSrc) {
        if (this.art) {
          this.destroyPlayer();
          this.initializePlayer();
        }
      }
    }
  }
}
</script>

<style scoped>
.art-player-wrapper {
  width: 100%;
  aspect-ratio: 16/9;
  background: #000;
  margin-bottom: 1rem;
  position: relative;
}

.art-video-player {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
}

:deep(.art-video-player video) {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>
// Bu dosya ile responsive tablolar oluşturuluyor. Mobil cihazlarda tabloların responsive olması için kullanılıyor.

document.addEventListener('DOMContentLoaded', function() {
    // Mobil kontrolü için fonksiyon
    function isMobile() {
        return window.innerWidth <= 768;
    }

    function cleanupResponsiveTables() {
        const tables = document.querySelectorAll('.table');
        tables.forEach(table => {
            const cells = table.querySelectorAll('tbody tr td');
            cells.forEach(cell => {
                const titleDiv = cell.querySelector('.td-title');
                const contentDiv = cell.querySelector('.td-content');
                
                if (titleDiv && contentDiv) {
                    while (contentDiv.firstChild) {
                        cell.appendChild(contentDiv.firstChild);
                    }
                    titleDiv.remove();
                    contentDiv.remove();
                }
            });
        });
    }

    function initResponsiveTables() {
        if (!isMobile()) {
            cleanupResponsiveTables();
            return;
        }

        const tables = document.querySelectorAll('.table');
        
        tables.forEach(table => {
            const headers = Array.from(table.querySelectorAll('thead th')).map(th => th.textContent.trim());
            
            const rows = table.querySelectorAll('tbody tr');

            rows.forEach(row => {
                const cells = row.querySelectorAll('td');
                
                cells.forEach((cell, index) => {
                    if (headers[index] && !cell.querySelector('.td-title')) {
                        const titleDiv = document.createElement('div');
                        titleDiv.className = 'td-title';
                        titleDiv.textContent = headers[index];
                        
                        const contentDiv = document.createElement('div');
                        contentDiv.className = 'td-content';

                        // Butonları içeren hücreleri kontrol et
                        const buttons = cell.querySelectorAll('.btn');
                        if (buttons.length > 1) {
                            const buttonContainer = document.createElement('div');
                            buttonContainer.className = 'flex space-x-2 flex-nowrap';
                            buttons.forEach(button => buttonContainer.appendChild(button.cloneNode(true)));
                            contentDiv.appendChild(buttonContainer);
                        } else {
                            while (cell.firstChild) {
                                contentDiv.appendChild(cell.firstChild);
                            }
                        }

                        // Metin içeriğini tek satırda göster
                        contentDiv.style.whiteSpace = 'nowrap';
                        contentDiv.style.overflow = 'hidden';
                        contentDiv.style.textOverflow = 'ellipsis';
                        
                        cell.appendChild(titleDiv);
                        cell.appendChild(contentDiv);
                    }
                });
            });
        });
    }

    initResponsiveTables();
    
    // Ekran boyutu değiştiğinde kontrol et
    window.addEventListener('resize', initResponsiveTables);
    
    // AJAX ile yüklenen içerik için gözlemci
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes.length) {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === 1 && (node.classList?.contains('table') || node.querySelector?.('.table'))) {
                        initResponsiveTables();
                    }
                });
            }
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
});
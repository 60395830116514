<template>
    <div class="relative">
        <!-- Tab Sistemi -->
        <div class="mb-4 border-b border-gray-200">
            <ul class="flex flex-wrap -mb-px">
                <li class="mr-2">
                    <a href="#"
                       :class="[activeTab === 'active' ? 'border-primary-500 text-primary-500 font-bold' : 'border-transparent hover:border-gray-300',
                               'inline-block p-4 border-b-2']"
                       @click.prevent="setActiveTab('active')">
                        Aktif Ödevler
                    </a>
                </li>
                <li class="mr-2">
                    <a href="#"
                       :class="[activeTab === 'passive' ? 'border-primary-500 text-primary-500 font-bold' : 'border-transparent hover:border-gray-300',
                               'inline-block p-4 border-b-2']"
                       @click.prevent="setActiveTab('passive')">
                        Geçmiş Ödevler
                    </a>
                </li>
            </ul>
        </div>

        <!-- Loader -->
        <div v-show="isLoading"
             class="fixed w-full h-full top-1/2 left-1/2 text-center bg-black bg-opacity-10 z-10"
             style="transform: translate(-50%,-50%)">
            <div class="w-full h-full flex items-center justify-center">
                <pulse-loader :color="'#03B0B9'" :size="'12px'"></pulse-loader>
            </div>
        </div>

        <!-- Tablo -->
        <div v-if="filteredTasks.length > 0" class="overflow-x-auto">
            <table class="min-w-full bg-white border-collapse border border-gray-200">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-300 text-left">Ders</th>
                    <th class="px-6 py-3 border-b border-gray-300 text-left">İçerik</th>
                    <th class="px-6 py-3 border-b border-gray-300 text-left">Başlangıç Tarihi</th>
                    <th class="px-6 py-3 border-b border-gray-300 text-left">Bitiş Tarihi</th>
                    <th class="px-6 py-3 border-b border-gray-300 text-left">Başarı Oranı</th>
                    <th class="px-6 py-3 border-b border-gray-300 text-left">Durum</th>
                    <th class="px-6 py-3 border-b border-gray-300 text-left"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="taskResult in filteredTasks" :key="taskResult.learning_task.id">
                    <td class="px-6 py-4 border-b border-gray-200">{{ taskResult.learning_task.course.name }}</td>
                    <td class="px-6 py-4 border-b border-gray-200">{{ taskResult.learning_task.title }}</td>
                    <td class="px-6 py-4 border-b border-gray-200">{{ taskResult.learning_task.start_time || '-' }}</td>
                    <td class="px-6 py-4 border-b border-gray-200">
                        <p>
                            {{ taskResult.learning_task.end_time || '-' }}
                        </p>
                        <p>
                            <span
                                class="text-xs text-white bg-pink-700 font-bold py-1 px-5 rounded-full"
                                v-if="isEndingSoon(taskResult.learning_task.ended_at)">
                                SON GÜN
                            </span>
                            <span
                                class="text-xs text-black bg-yellow-400 font-bold py-1 px-5 rounded-full"
                                v-else-if="new Date(taskResult.learning_task.ended_at) > new Date()">
                                SÜRE AZALIYOR
                            </span>
                        </p>
                    </td>
                    <td class="px-6 py-4 border-b border-gray-200">
                        <template v-if="taskResult.score !== null">
                            <template v-if="taskResult.learning_task.assessment_type === 1">
                                {{ taskResult.score ? 'Yapıldı' : 'Yapılmadı' }}
                            </template>
                            <template v-else>
                                % {{ taskResult.score }}
                            </template>
                        </template>
                        <template v-else>
                            -
                        </template>
                    </td>
                    <td class="px-6 py-4 border-b border-gray-200">
                        <div v-if="!taskResult.learning_task.is_started">Ödev başlamadı</div>
                        <div v-else-if="!taskResult.learning_task.is_ended">
                            <div
                                v-if="taskResult.learning_task.learning_content || taskResult.learning_task.learning_test">
                                <div v-if="taskResult.is_finished">Tamamlandı</div>
                                <div v-else>
                                    <a class="btn btn-sm btn-yellow" :href="taskResult.learning_task.url">
                                        {{ taskResult.is_waiting ? 'Ödeve Başla' : 'Ödeve Devam Et' }}
                                    </a>
                                </div>
                            </div>
                            <div v-else-if="taskResult.learning_task.document">
                                <div v-if="taskResult.is_finished">
                                    Tamamlandı
                                    <button type="button" class="ml-2 btn btn-sm btn-yellow"
                                            @click="openTask(taskResult)">
                                        Ödevi Gör
                                    </button>
                                </div>
                                <div v-else-if="taskResult.is_waiting">
                                    <button type="button" class="btn btn-sm btn-yellow" @click="openTask(taskResult)">
                                        Ödeve Başla
                                    </button>
                                </div>
                                <div v-else>
                                    <template v-if="!taskResult.learning_task.document.type.auto_assessment">
                                        Kontrol bekliyor
                                        <button type="button" class="btn btn-sm btn-yellow"
                                                @click="openTask(taskResult)">
                                            Ödevi Gör
                                        </button>
                                    </template>
                                    <button v-else type="button" class="btn btn-sm btn-yellow"
                                            @click="openTask(taskResult)">
                                        Ödeve Devam Et
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="taskResult.is_finished">Tamamlandı</div>
                            <div v-else>Süresi geçti</div>
                        </div>
                    </td>
                    <td class="px-6 py-4 border-b border-gray-200">
                        <div v-if="taskResult.learning_task.description">
                            <popover icon="info" :text="taskResult.learning_task.description" color="text-black"
                                     position="left"></popover>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="mt-4 alert alert-info text-center">
            <template v-if="props.user?.school?.is_premium">
                Şu anda aktif bir ödevin bulunmuyor. Aktif bir ödevinin olması için öğretmenlerinin ödev gönderimi
                yapması gerekmektedir.
            </template>
            <template v-else>
                Şu anda aktif bir ödevin bulunmuyor. Öğretmenlerini Derslig’e davet ederek sana ödev göndermesini
                sağlayabilirsin.
            </template>
        </div>

        <!-- Show more butonu -->
        <div class="text-center py-4" v-if="currentPage < totalTasksPage && filteredTasks.length > 0">
            <a href="javascript:void(0)" class="btn btn"
               @click="getMoreTaskData(currentPage)">
                Daha Fazla Göster
            </a>
        </div>

        <!-- Document Modal -->
        <DocumentAnswerModal ref="documentAnswerModal" :solution-mode="true" :files-url="props.filesUrl"
                             :data="activeTaskResult?.learning_task?.document"
                             @setTaskResult="setTaskResult"></DocumentAnswerModal>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import DocumentAnswerModal from "../components/modal/DocumentAnswerModal.vue";
import Popover from "../components/Popover.vue";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

const props = defineProps(['taskResults', 'filesUrl', 'user', 'totalTasksPage']);

const taskResults = ref(props.taskResults);
const totalTasksPage = ref(props.totalTasksPage);
const documentAnswerModal = ref(null);
const activeTaskResult = ref(null);
const currentPage = ref(1);
const isLoading = ref(false);
const activeTab = ref('active');

// Aktif/Pasif ödev filtresi
const filteredTasks = computed(() => {
    if (activeTab.value === 'active') {
        return taskResults.value.filter(task => {
            const now = new Date();
            const endDate = new Date(task.learning_task.ended_at);
            const startDate = new Date(task.learning_task.started_at);

            // Aktif ödevler:
            // 1. Başlangıç tarihi gelmiş
            // 2. Bitiş tarihi gelmemiş
            // 3. Henüz tamamlanmamış
            return startDate <= now &&
                   endDate >= now &&
                   task.status !== 2;
        });
    } else {
        return taskResults.value.filter(task => {
            const now = new Date();
            const endDate = new Date(task.learning_task.ended_at);

            // Geçmiş ödevler:
            // 1. Bitiş tarihi geçmiş VEYA
            // 2. Ödev tamamlanmış
            return endDate < now || task.status === 2;
        });
    }
});

const setActiveTab = (tab) => {
    activeTab.value = tab;
};

const openTask = (taskResult) => {
    activeTaskResult.value = taskResult;
    documentAnswerModal.value.isModalOpen = true;
};

const getMoreTaskData = async (intCurrentPage) => {
    isLoading.value = true;
    try {
        const response = await axios.get(`/odevlerim?currentPage=${intCurrentPage + 1}`);
        taskResults.value.push(...response.data.tasks);
        currentPage.value = intCurrentPage + 1;
    } catch (error) {
        console.error('Error fetching tasks:', error);
    } finally {
        isLoading.value = false;
    }
};

const isEndingSoon = endedAt => {
    const now = new Date();
    const endDate = new Date(endedAt);
    const diff = endDate - now;
    return diff > 0 && diff < 86400000; // 1 day
}
</script>

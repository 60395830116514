<template>
    <div>

        <div class="w-full flex space-x-2 items-center justify-center py-12">
            <div class="w-48 p-2 bg-primary-500 rounded-lg">
                <div>
                    <input v-model="hour" type="text" class="w-full text-center p-3 text-xl font-bold bg-primary-500 rounded-lg text-white">
                </div>
                <div class="w-full flex text-center items-center">
                    <div class="bg-yellow-default px-4 py-2  hover:bg-secondary rounded-lg cursor-pointer text-xl " @click="hour > 0 &&  hour--">-</div>
                    <div class="px-4 py-2 text-sm w-full text-white">Saat</div>
                    <div class="bg-yellow-default px-4 py-2 hover:bg-secondary rounded-lg cursor-pointer text-xl" @click="hour++">+</div>
                </div>
            </div>

            <div class="w-48 p-2 bg-primary-500 rounded-lg">
                <div>
                    <input v-model="minute" type="text" class="w-full text-center p-3 text-xl font-bold bg-primary-500 rounded-lg text-white">
                </div>
                <div class="w-full flex text-center items-center">
                    <div class="bg-yellow-default px-4 py-2  hover:bg-secondary rounded-lg cursor-pointer text-xl" @click="minute > 0 && minute--">-</div>
                    <div class="px-4 py-2 text-sm w-full text-white">Dakika</div>
                    <div class="bg-yellow-default px-4 py-2 hover:bg-secondary rounded-lg cursor-pointer text-xl" @click="minute < 59 && minute++">+</div>
                </div>
            </div>

            <div class="w-48 p-2 bg-primary-500 rounded-lg">
                <div>
                    <input v-model="second" type="text" class="w-full text-center p-3 text-xl font-bold bg-primary-500 rounded-lg text-white">
                </div>
                <div class="w-full flex text-center items-center">
                    <div class="bg-yellow-default px-4 py-2  hover:bg-secondary rounded-lg cursor-pointer text-xl" @click="second > 0 && second--">-</div>
                    <div class="px-4 py-2 text-sm w-full text-white">Saniye</div>
                    <div class="bg-yellow-default px-4 py-2 hover:bg-secondary rounded-lg cursor-pointer text-xl" @click="second < 59 && second++">+</div>
                </div>
            </div>
        </div>

        <div class="flex space-x-2 p-4 items-center justify-center">
            <div class="rounded-lg bg-secondary hover:bg-yellow-default hover:text-black px-4 py-2 text-white flex space-x-2 items-center justify-center cursor-pointer"
            v-for="t in timers" :key="t.id"
                @click="addTimer(t)"
            >
                <div><Plus size="20"/></div>
                <div>{{ t.title }}</div>
            </div>
        </div>


        <div class="flex flex-wrap">
            <div class="w-1/2" v-for="i,key in selectTimers" :key="key">
                <div class="p-2 h-full">
                    <div class="p-4 bg-primary-darkest rounded-lg h-full  relative overflow-hidden">
                        <div class="flex justify-between">
                            <div class="flex p-2 items-center justify-center text-white text-xl font-bold">
                                {{ key + 1 }}. {{  timers.filter(t => t.type == i.type)[0].title }}
                            </div>
                            <div class="flex space-x-1">
                                <button class="bg-secondary py-2 px-4  text-white rounded-lg" @click="deleteTimer(i.id)">
                                    Kapat
                                </button>
                            </div>
                        </div>

                        <Countdown  :hour="i.hour" :second="i.second" :minute="i.minute" :index="key" v-if="i.type == 'countdown'"/>
                        <Stopwatch  :hour="i.hour" :second="i.second" :minute="i.minute" :index="key" v-if="i.type == 'stopwatch'"/>
                        <Time  :hour="i.hour" :second="i.second" :minute="i.minute" :index="key" v-if="i.type == 'time'"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import {Plus, Delete} from '@icon-park/vue-next'
import {ref} from 'vue'
import Countdown from './Timer/Countdown.vue';
import Stopwatch from './Timer/Stopwatch.vue';
import Time from './Timer/Time.vue';
import { earnTimerBadge } from '../../../helpers/badge'


const hour      = ref(0)
const minute    = ref(30)
const second    = ref(0)

const timers = [
    {
        title: 'Geri Sayım',
        type:'countdown'
    },
    {
        title: 'Kronometre',
        type:'stopwatch'
    },
    {
        title: 'Saat',
        type:'time'
    }
]

const getRandomNumber = () => {
    return Math.floor(Math.random() * 10000);
}

// const selectTimers = ref([
//     {id: getRandomNumber(), type: 'countdown', hour: hour.value, minute:minute.value, second:second.value},
//     {id: getRandomNumber(), type: 'stopwatch', hour: hour.value, minute:minute.value, second:second.value}
// ])

const selectTimers = ref([])

const deleteTimer = (id) => {
    selectTimers.value = selectTimers.value.filter(i => i.id != id)
}

const addTimer = (t) => {
    selectTimers.value.push({
        id: getRandomNumber(),
        type: t.type,
        hour: hour.value,
        second: second.value,
        minute: minute.value
    });
    
    earnTimerBadge()
        .then(result => {
            if (result && result.status === 'ok' && result.data) {
            }
        })
        .catch(error => {
        });
}

</script>

<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="isModalOpen = false">
            <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"/>
                </TransitionChild>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full border-2 border-gray-900">
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="sm:flex sm:items-start">
                                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-default bg-opacity-10 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon class="h-6 w-6 text-yellow-default" aria-hidden="true"/>
                                </div>
                                <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <DialogTitle as="h3" class="text-lg leading-6 font-semibold text-gray-900 mb-2">
                                        {{ contentTitle }} Hata Bildirimi
                                    </DialogTitle>
                                    <div v-show="!isSending && !isCompleted">
                                        <div class="mb-2" v-show="contentSlide === ''">
                                            <label class="font-medium text-sm text-gray-900">
                                                Hatanın bulunduğu soru numarası:
                                            </label>
                                            <input type="number" class="form-control" min="1" max="999" v-model="contentQuestionNumber">
                                        </div>
                                        <div class="mb-2" v-show="contentSlide !== ''">
                                            <label class="font-medium text-sm text-gray-900">
                                                Hatanın bulunduğu slayt numarası:
                                            </label>
                                            <input type="number" class="form-control" min="1" max="999" v-model="contentSlide" disabled="disabled">
                                        </div>
                                        <div class="mb-2">
                                            <textarea placeholder="İçerik ile ilgili hatayı ayrıntılı bir şekilde yazınız"
                                                  class="w-full form-control" v-model="description"></textarea>
                                        </div>
                                        <div class="font-bold text-secondary text-xs">
                                            {{ errorMessage }}
                                        </div>
                                    </div>
                                    <div v-show="isSending" class="w-full text-center">
                                        <pulse-loader :loading="'Gönderiliyor...'" :color="'#03B0B9'" :size="'12px'"></pulse-loader>
                                    </div>
                                    <div v-show="isCompleted" class="font-bold text-primary-500 text-sm">
                                        Hata bildiriminiz başarıyla iletilmiştir.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <span v-show="!isCompleted">
                                   <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-500
                                    text-base font-medium text-white hover:bg-opacity-80 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm" 
                                    @click="send" 
                                    :disabled="!isFormValid || isSending"
                                    :class="{'opacity-50 cursor-not-allowed': !isFormValid || isSending}">
                                Gönder
                                </button>
                            </span>
                            <button type="button"
                                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base
                                    font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    @click="isModalOpen = false" ref="cancelButtonRef">
                                Kapat
                            </button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {onMounted, ref, computed} from 'vue'
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {ExclamationIcon} from '@heroicons/vue/outline'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import EventBus from './../../events'

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ExclamationIcon,
        PulseLoader,
    },
    props: {
        endpoint: String
    },
    setup(props) {
        const isModalOpen = ref(false);
        const isSending = ref(false);
        const isCompleted = ref(false);
        const contentQuestionNumber = ref('');
        const description = ref('');
        const errorMessage = ref('');
        const contentType = ref('');
        const contentTitle = ref('');
        const learningContentId = ref('');
        const contentUrl = ref('');
        const contentSlide = ref('');
        const successMessage = ref('');

        const isFormValid = computed(() => {
            if (isSending.value) {
                return false;
            }
            
            if (contentSlide.value === '' && contentQuestionNumber.value === '') {
                return false;
            }
            
            if (description.value.length < 10) {
                return false;
            }
            
            return true;
        });

        onMounted(() => {
            EventBus.on('open-report-modal', (data) => {
                contentType.value = data.type;
                contentTitle.value = data.title;
                learningContentId.value = data.learningContentId;
                contentUrl.value = data.url;
                contentSlide.value = data.slide;

                isModalOpen.value = true;
                isCompleted.value = false;
                contentQuestionNumber.value = '';
                errorMessage.value = '';
                description.value = '';
            });
        })

        const send = () => {
            if (contentSlide.value === '' && contentQuestionNumber.value === '') {
                errorMessage.value = 'Lütfen hatanın bulunduğu soru numarasını giriniz.';
                return false;
            }

            if (description.value.length < 10) {
                errorMessage.value = 'Lütfen hatayı detaylı bir şekilde açıklayınız.';
                return false;
            }

            let message = contentUrl.value + ' adresindeki içerikte ';

            if (contentSlide.value !== '') {
                message += contentSlide.value + ' numaralı slaytta hata bulunuyor.';
            } else {
                message += contentQuestionNumber.value + ' numaralı soruda hata bulunuyor.';
            }

            message += ' Hata açıklaması: ' + description.value;

            let subject = contentType.value + ' Hata Bildirimi';

            isSending.value = true;

            if (contentSlide.value !== '') {
                axios.post(props.endpoint, {
                    learningContentId: learningContentId.value,
                    contentSlide: contentSlide.value,
                    description: description.value
                }).then((response) => {
                    if (response.data.error === "") {
                        isCompleted.value = true;
                        errorMessage.value = '';
                        successMessage.value = response.data.message;
                    } else {
                        errorMessage.value = response.data.error;
                    }
                }).catch((error) => {
                    errorMessage.value = error.response.data.error;
                }).then(() => {
                    isSending.value = false;
                });
            } else {
                axios.post(props.endpoint, {
                    type: 2,
                    subject: subject,
                    message: message
                }).then((response) => {
                    if (response.data.success) {
                        isCompleted.value = true;
                        errorMessage.value = '';
                        successMessage.value = response.data.message;
                    } else {
                        errorMessage.value = response.data.error;
                    }
                }).catch((error) => {
                    errorMessage.value = error.response.data.error;
                }).then(() => {
                    isSending.value = false;
                });
            }
        }

        return {
            isModalOpen,
            isSending,
            isCompleted,
            contentQuestionNumber,
            description,
            errorMessage,
            contentTitle,
            contentSlide,
            send,
            isFormValid
        }
    },
}
</script>
